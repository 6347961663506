/**
 * Device 新增
 * @type {{}}
 */
import Vue from "vue";
import { Message,Modal,Breadcrumb,BreadcrumbItem,Progress,Select,Option,Input,Icon,Button,Cascader,Tabs,TabPane ,Table,Spin,Carousel,CarouselItem,Tooltip,Dropdown,DropdownMenu,DropdownItem,Poptip} from 'view-design';
import {
    getDeviceModelService,
    getManufacturerService,
    getIdManufacturerService,
    getDeviceInfoService,
    getDeviceInfoIdService,
    getStructuralInfoIdService,
    getStructuralDeviceIdService,
    deviceDataColumns,
    faultDataColumns,
    reasonDataColumns,
    structuralElementColumns,
    deviceTabs,
    parameterColumns,
} from "@/service/device-info-service";
Vue.component('Breadcrumb', Breadcrumb);
Vue.component('BreadcrumbItem', BreadcrumbItem);
Vue.component('Select', Select);
Vue.component('Option', Option);
Vue.component('Input', Input);
Vue.component('Icon', Icon);
Vue.component('Button', Button);
Vue.component('Cascader', Cascader);
Vue.component('Modal', Modal);
Vue.component('Tabs', Tabs);
Vue.component('TabPane', TabPane);
Vue.component('Table', Table);
Vue.component('Spin', Spin);
Vue.component('Carousel', Carousel);
Vue.component('CarouselItem', CarouselItem);
Vue.component('Progress', Progress);
Vue.component('Tooltip', Tooltip);
Vue.component('Dropdown', Dropdown);
Vue.component('DropdownMenu', DropdownMenu);
Vue.component('DropdownItem', DropdownItem);
Vue.component('Poptip', Poptip);
import { mapMutations, mapState } from "vuex";
import { renderSize,saveImage } from '@/utils/tools'
import $event, { EVENT_NAME } from "@/utils/event-emitter";
import ViewImage from '@/components/ViewImage/viewImage.vue'
const DeviceInfo = {
    name:'DeviceInfo',
    components:{ ViewImage },
    computed: {
        ...mapState({
            classifyMenuArray: state => state.system.classifyMenuArray, //分类菜单数据
            selectedMenuId: state => state.system.selectedMenuId, //选中的菜单Id
            deviceInfo: state => state.system.deviceInfo, //设备id
            tabId:state => state.system.tabId, //tabId
            menuVisible: state => state.system.menuVisible, //菜单显示状态
            structuralId: state => state.system.structuralId, //结构部件id
        }),
        /**
         * 文件格式验证
         * @returns {function(*=, *): boolean}
         */
        onFileFormatCheck(){
            return (value) =>{
                let status
                let suffixIndex = value.lastIndexOf('.')
                let suffix=value.substring(suffixIndex+1).toUpperCase();
                if(suffix!="BMP"&&suffix!="JPG"&&suffix!="JPEG"&&suffix!="PNG"&&suffix!="GIF"){
                    status = false
                }else{
                    status = true
                }
                return status
            }
        }
    },
    data() {
        return {
            renderSize:renderSize, //文件大小转换
            submitLoading:false, //提交等待
            deviceDataColumns:deviceDataColumns, //资料文件显示字段
            faultDataColumns:faultDataColumns, //故障分析显示字段
            reasonDataColumns:reasonDataColumns, //可能的原因显示字段
            structuralElementColumns:structuralElementColumns, //结构部件显示字段
            deviceTabs:deviceTabs, //标签页数据
            parameterColumns:parameterColumns,
            deviceInfoLoading:false, //设备信息loading
            deviceModelVisible:false, //新增设备型号可显示
            manufacturerVisible:false, //新增设备厂家可显示
            addFaultVisible:false, //新增故障可显示
            faultStatus:false, //新增故障状态
            reasonVisible:false, //可能的原因可显示
            reasonLoading:false, //可能的原因loading
            reasonLookVisible:false, //可能的原因查看可显示
            reasonBigLookVisible:false, //可能的原因查看大图可显示
            imageBigLookVisible:false, //图册查看大图可显示
            faultTableHeight:0, //表格高度
            parameterHeight:0,
            imageBigArray:[], //图册数据
            imageBigTitle:'', //图册名称
            imageName:'',
            viewImageVisible:false,
            structuralInfo:'', //结构部件的信息数量
            principleTab:1,
            principleObj:{},
            carouselNumber:1,
            imageBigNumber:0,
            imageBigActive:0, //大图定位值
            deviceStructuralArray:[], //结构部件列表数据
            deviceTabsNumber:1,
            imageObj:{},
            imageWidth:0,
            imageHeight:0,
            imageLeft:0,
            imageTop:0,
            currentX:0, //记录落点的x坐标
            currentY:0, //记录落点的Y坐标
            offsetWidth:0, //记录滚动内容宽度
            imageRatio:100,
            complete:0, //上传进度
            speedList:[], //上传文件临时存储
            speedVisible:false, //上传进度可显示
            reasonStatus:false, // 解决方案弹框title状态
            failureAnalysisFaultId:'', //故障描述id
            imageSrc:'', //上传缩略图
            fileHandleVisible:false, //上传操作弹框可显示
            deviceModelArray:[], //获取所有设备型号
            manufacturerArray:[], //获取所有生产厂家
            deviceInfoObj:{}, //设备信息数据
            deviceInfoArray:[], //设备信息数据集合
            deviceModelManufacturerArray:[], //根据设备型号拉取的生产厂家
            deviceSelectPart:{}, //选中的结构部件
            deviceListParams:{ //提交的数据集
                checkInput:[],//选择的数据列表项
            },
            newlyParams:{  //保存数据集
                deviceType:[], //选择的设备分类
                deviceName:'', //设备名称
                deviceModel:'', //选择的设备型号
                manufacturer:'', //选择的生产厂家
                files:[] //选择的图片流
            },
            deviceParams:{ //新增设备型号数据集
                deviceName:'', //设备型号名称
                manufacturer:[], //生产厂家
            },
            manufacturerParams:{ //新增生产厂家数据集
                manufacturerName:'',
            },
            faultParams:{ //故障分析数据集
                id:'',
                code:'', //故障名称
                describe:'', //故障描述
            },
            reasonParams: { //可能原因数据集
                reason:'',
                solution:'',
            },
            faultsList:[], //故障分析数据集
            reasonArray:{}, //可能的原因数据集
        }
    },
    created() {
        this.SET_STRUCTURAL_ID('')
        this.deviceTabsNumber = this.tabId
        this.onStructuralDeviceId().then()
        this.onDeviceInfo().then(()=>{
            if(this.tabId === 6 && this.structuralId === ''){
                this.onDeviceModelAll().then()
                this.onManufacturerAll().then()
            }
            if(this.structuralId!==''){
                this.onStructuralInfoIdService().then()
            }else{
                this.onDeviceInfoIdService().then()
            }
        })
        /**
         * 可能的原因可显示
         */
        $event.$on(EVENT_NAME.REASON_MODAL_VISIBLE, (obj) => {
            this.failureAnalysisFaultId = obj.id
            switch (obj.name){
                case 'look':
                    this.reasonArray = obj.reasons
                    this.reasonVisible = false
                    this.reasonLookVisible = true
                    break;
                case 'bigLook':
                    this.reasonArray = obj.reasons
                    this.carouselNumber = obj.key
                    this.reasonVisible = false
                    this.reasonLookVisible = false
                    this.reasonBigLookVisible = true
                    break;
            }
        });
        this.faultTableHeight = window.innerHeight - 210
        this.parameterHeight = window.innerHeight - 120
    },
    mounted() {
        window.onresize = () => {
            return (() => {
                this.faultTableHeight = window.innerHeight - 210
                this.parameterHeight = window.innerHeight - 120
            })()
        };
    },
    methods: {
        ...mapMutations('system',['SET_TAB_ID','SET_DEVICE_INFO','SET_ELECTRICAL_ID','SET_STRUCTURAL_ID']),
        /**
         * 切换tabs
         * @param n
         */
        onTabsSwitch(n){
            if(n === this.deviceTabsNumber){
                return
            }
            this.deviceInfoArray = []
            this.deviceTabsNumber = n
            this.SET_TAB_ID(n)
            this.deviceListParams.checkInput = []
            this.onDeviceInfo().then(()=>{
                if(this.tabId === 6){
                    this.onDeviceModelAll().then()
                    this.onManufacturerAll().then()
                }
            })
        },
        /**
         * 选中的资料
         * @param obj
         */
        onSelectPrinciple(obj){
            this.principleObj = obj
        },
        onDevice(){
            this.SET_STRUCTURAL_ID('')
            this.onDeviceInfo().then()
        },
        /**
         * 查看大图
         * @param key
         */
        onLookImage(obj) {
            this.onImageLoad(obj.picUrl)
            this.imageObj = obj
            this.reasonVisible = false
            this.reasonLookVisible = false
            this.reasonBigLookVisible = true
        },
        /**
         * 图片初始化
         * @param src
         */
        onImageLoad(src){
            let image = new Image();
            image.src = src
            image.onload = ()=>{
                if(image.height>window.innerHeight){
                    if(image.width>image.height){
                        this.imageWidth = image.height/image.width*window.innerHeight
                    }{
                        this.imageWidth = image.width/image.height*window.innerHeight
                    }
                    this.imageHeight = window.innerHeight
                }else{
                    this.imageWidth = image.width
                    this.imageHeight = image.height
                }
                this.imageLeft = (window.innerWidth- this.imageWidth)/2
                this.imageTop = (window.innerHeight- this.imageHeight)/2
            }
        },
        /**
         * 图片放大
         * @param name
         * @param src
         */
        onImageAmplify(name,src,fileUrl) {
            console.log(this.deviceInfoObj)
            let suffixIndex = fileUrl.lastIndexOf('.')
            let suffix=fileUrl.substring(suffixIndex+1).toUpperCase();
            if(suffix!="BMP"&&suffix!="JPG"&&suffix!="JPEG"&&suffix!="PNG"&&suffix!="GIF"){
                window.open(src,'_blank')
            }else{
                this.imageName = name
                this.imageSrc = src
                this.viewImageVisible = true
            }
        },
        structuralImage(row){
            console.log(row)
            this.imageObj.picUrl = row.file
            this.imageObj.solution = row.partsName
            this.onLookImage(this.imageObj)
        },
        /**
         * 查看文件
         * @param row
         */
        onLookFile(row){
            this.imageObj.picUrl = row.file
            this.imageObj.solution = row.fileName
            let suffixIndex = row.fileName.lastIndexOf('.')
            let suffix=row.fileName.substring(suffixIndex+1).toUpperCase();
            if(suffix!="BMP"&&suffix!="JPG"&&suffix!="JPEG"&&suffix!="PNG"&&suffix!="GIF"){
                window.open(row.file,'_blank')
            }else{
                this.onLookImage(this.imageObj)
            }
        },
        /**
         * 选中结构部件
         * @param item
         */
        onSelectPart(item) {
            this.SET_STRUCTURAL_ID(item.id)
            this.$nextTick(()=>{
                this.deviceSelectPart = item
                this.onStructuralInfoIdService().then()
                this.onDeviceInfo().then()
            })
            this.$forceUpdate()
        },
        /**
         * 替换属性名
         * @param data
         */
        onReplaceAttribute(data){
            let item = []
            data.map(list => {
                let newData = {}
                newData.value = list.id;
                newData.label = list.name;
                newData.children = list.childNode;
                if(list.childNode){
                    newData.children = this.onReplaceAttribute(list.childNode)
                }
                item.push(newData)
            })
            return item
        },
        /**
         * 文件下载
         * @param res
         * @param fileName
         */
        onDownLoad(res,fileName){
            if (!res) {
                return
            }
            if (window.navigator.msSaveBlob) {  // IE以及IE内核的浏览器
                try {
                    window.navigator.msSaveBlob(res, fileName)  // res为接口返回数据，这里请求的时候已经处理了，如果没处理需要在此之前自行处理var data = new Blob([res.data]) 注意这里需要是数组形式的,fileName就是下载之后的文件名
                    // window.navigator.msSaveOrOpenBlob(res, fileName);  //此方法类似上面的方法，区别可自行百度
                } catch (e) {
                    console.log(e)
                }
            } else {
                let url = window.URL.createObjectURL(new Blob([res]))
                let link = document.createElement('a')
                link.style.display = 'none'
                link.href = url
                link.setAttribute('download', fileName)// 文件名
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link) // 下载完成移除元素
                window.URL.revokeObjectURL(url) // 释放掉blob对象
            }
        },
        /**
         * 鼠标滚轮事件
         * @param e
         */
        onMousewheel(e) {
            if(e.wheelDelta > 0){
                this.onImageEnlarge()
            }else{
                this.onImageNarrow()
            }
        },
        /**
         * 记录按下的事件
         * @param e
         * @returns {{x: number, y: number}}
         */
        onMouseDown(e) {
            this.drawing = true;
            this.currentX = e.offsetX
            this.currentY = e.offsetY
        },
        /**
         * 记录抬起的事件
         * @param e
         */
        onMouseUp() {
            if (!this.drawing) { return; }
            this.drawing = false;
        },
        /**
         * 记录移动的事件
         * @param e
         */
        onMouseMove(e) {
            if (!this.drawing) { return; }
            this.imageLeft = e.clientX - this.currentX
            this.imageTop = e.clientY - this.currentY
        },
        /**
         * 图片放大比例
         */
        onImageEnlarge() {
            if((this.imageRatio + 25 ) > 1000){
                this.imageRatio = 1000
                return
            }
            this.imageRatio+=25

            this.imageLeft -= (this.imageWidth*(this.imageRatio/100) - this.imageWidth*((this.imageRatio-25)/100))/2
            this.imageTop -= (this.imageHeight*(this.imageRatio/100) - this.imageHeight*((this.imageRatio-25)/100))/2
        },
        /**
         * 图片缩小比例
         */
        onImageNarrow() {
            if((this.imageRatio - 25 ) < 25){
                this.imageRatio = 25
                return
            }
            this.imageRatio-=25
            this.imageLeft += (this.imageWidth*(this.imageRatio/100) - this.imageWidth*((this.imageRatio-25)/100))/2
            this.imageTop += (this.imageHeight*(this.imageRatio/100) - this.imageHeight*((this.imageRatio-25)/100))/2
        },
        /**
         * 还原图片
         */
        onReduction() {
            this.imageRatio = 100
            this.imageLeft = (window.innerWidth - this.imageWidth*(this.imageRatio/100))/2
            this.imageTop = (window.innerHeight - this.imageHeight*(this.imageRatio/100))/2
        },

        /**
         * 查看图册
         * @param name
         * @param row
         * @param key
         */
        onImageSwitch(row,obj,key){
            this.onReduction()
            this.imageBigArray = obj
            this.imageBigActive = key
            this.imageBigLookVisible = true
            this.imageObj.picUrl = row.file
            this.imageObj.solution = row.fileName
            this.onImageLoad(row.file)
            this.onImageActive(key)
        },
        /**
         * 列表左移动
         */
        onImageLeft() {
            if(this.imageBigNumber === 0){
                return
            }
            this.$nextTick(()=>{
                this.offsetWidth = Math.ceil(document.getElementById('carouselId').offsetWidth)
                if(this.imageBigNumber - Math.floor(this.offsetWidth/106)<0){
                    this.imageBigNumber = 0
                }else{
                    this.imageBigNumber -= Math.floor(this.offsetWidth/106)
                }
            })
        },
        /**
         * 列表右移动
         */
        onImageRight() {
            this.$nextTick(()=>{
                this.offsetWidth = Math.ceil(document.getElementById('carouselId').offsetWidth)
                if(this.imageBigNumber + Math.floor(this.offsetWidth/106)>Math.floor((this.imageBigArray.length*106-this.offsetWidth)/106)){
                    this.imageBigNumber = Math.ceil((this.imageBigArray.length*106 - this.offsetWidth)/106)
                }else{
                    this.imageBigNumber += Math.floor(this.offsetWidth/106)
                }
            })
        },
        /**
         * 大图向左切换
         */
        onImageBigLeft(){
            if(this.imageBigActive === 0){
                this.imageBigActive = this.imageBigArray.length - 1
            }else{
                this.imageBigActive-=1
            }
            this.onReduction()
            this.imageObj.picUrl = this.imageBigArray[this.imageBigActive].file
            this.imageObj.solution = this.imageBigArray[this.imageBigActive].fileName
            this.onImageLoad(this.imageObj.picUrl)
            this.onImageActive(this.imageBigActive)
        },
        /**
         * 大图向右切换
         */
        onImageBigRight() {
            if(this.imageBigActive+1>=this.imageBigArray.length){
                this.imageBigActive = 0
            }else{
                this.imageBigActive+=1
            }
            this.onReduction()
            this.imageObj.picUrl = this.imageBigArray[this.imageBigActive].file
            this.imageObj.solution = this.imageBigArray[this.imageBigActive].fileName
            this.onImageLoad(this.imageObj.picUrl)
            this.onImageActive(this.imageBigActive)
        },
        /**
         * 计算列表图片位置
         * @param key
         */
        onImageActive(key) {
            this.$nextTick(()=>{
                this.offsetWidth = Math.ceil(document.getElementById('carouselId').offsetWidth)
                if(key+1>Math.ceil(this.offsetWidth/106/2)){  //让选中的图片每次保持在页面中间位置 计算方式（宽/2）向上取整
                    if((key+1)-Math.ceil(this.offsetWidth/106/2) < this.imageBigArray.length-Math.floor(this.offsetWidth/106)){
                        this.imageBigNumber = (key+1)-Math.ceil(this.offsetWidth/106/2)
                    }else{
                        this.imageBigNumber = this.imageBigArray.length-Math.floor(this.offsetWidth/106)
                    }
                }else{
                    this.imageBigNumber = 0
                }
            })
        },
        /**
         * 编辑电气图册
         * @param id
         */
        onElectricalEdit(id){
            this.SET_ELECTRICAL_ID(id)
            this.$router.push('/device/electrical/config')
        },
        /**
         * 下载图片
         * @param obj
         */
        onDownLoadImage(obj) {
            saveImage(obj.file,obj.fileName)
        },
        /**
         * 获取所有设备型号
         */
        async onDeviceModelAll() {
            try {
                const { code,data } = await getDeviceModelService()
                if(code === 'SUCCESS'){
                    this.deviceModelArray = data
                }else{
                    Message.error('请求异常，请联系管理员！')
                }

            }catch (error){
                console.log(error)
            }
        },
        /**
         * 获取所有生产厂家
         */
        async onManufacturerAll() {
            try {
                const { code,data } = await getManufacturerService()
                if(code === 'SUCCESS'){
                    this.manufacturerArray = data
                }else{
                    Message.error('请求异常，请联系管理员！')
                }

            }catch (error){
                console.log(error)
            }
        },
        /**
         * 根据设备型号Id查询生产厂家
         */
        async onIdManufacturer() {
            try {
                this.newlyParams.manufacturer = ''
                let params = {
                    id:this.newlyParams.deviceModel
                }
                const { code,data } = await getIdManufacturerService(params)
                if(code === 'SUCCESS'){
                    this.deviceModelManufacturerArray = data
                }else{
                    Message.error('请求异常，请联系管理员！')
                }

            }catch (error){
                console.log(error)
            }
        },
        /**
         * 根据设备id查询当前条数据
         */
        async onDeviceInfo() {
            try {
                this.deviceInfoLoading = true
                this.deviceInfoArray = []
                let params = {
                    id:this.deviceInfo.id,
                    dataType:this.tabId,
                    partsId:this.structuralId
                }
                const { code,data } = await getDeviceInfoService(params)
                if(code === 'SUCCESS'){
                    if(this.tabId === 6){
                        this.deviceInfoObj = data
                        this.newlyParams.deviceModel = data.equipmentModelId
                        this.newlyParams.deviceName = data.name
                        this.newlyParams.deviceType = data.nodeIdList
                        this.imageSrc = data.equipmentPictures
                        if(this.structuralId===''){
                            this.onIdManufacturer().then(()=>{
                                this.newlyParams.manufacturer = data.equipmentManufacturerId
                            })
                        }else{
                            this.deviceSelectPart = data
                        }
                    }else if(this.tabId === 7 || this.tabId === 8){
                        this.deviceInfoObj = data
                        if(this.tabId === 8){
                            if(this.deviceInfoObj.detailDTOList.length!==0){
                                for(let i in this.deviceInfoObj.detailDTOList){
                                    let suffixIndex = this.deviceInfoObj.detailDTOList[i].fileUrl.lastIndexOf('.')
                                    let suffix=this.deviceInfoObj.detailDTOList[i].fileUrl.substring(suffixIndex+1).toUpperCase();
                                    if(suffix!="BMP"&&suffix!="JPG"&&suffix!="JPEG"&&suffix!="PNG"&&suffix!="GIF"){
                                        this.deviceInfoObj.detailDTOList[i].fileFormat = 'video'
                                    }else{
                                        this.deviceInfoObj.detailDTOList[i].fileFormat = 'image'
                                    }
                                }
                                this.principleObj = this.deviceInfoObj.detailDTOList[0]
                            }
                            if(this.deviceInfoObj.detailDTOList.length === 0 && this.deviceInfoObj.principleContent!==''){
                                this.principleTab = 2
                            }else{
                                this.principleTab = 1
                            }
                        }
                    }else{
                        for(let i in data){
                            data[i].nameStatus = false
                            data[i].visible = false

                            if(this.tabId === 4){
                                if(data[i].childNode.length!==0){

                                    for(let j in data[i].childNode){
                                        data[i].childNode[j].visible = false
                                    }
                                }
                            }
                        }
                        this.deviceInfoArray = data
                    }
                }else{
                    Message.error('请求异常，请联系管理员！')
                }
                this.deviceInfoLoading = false
            }catch (error){
                this.deviceInfoLoading = false
                console.log(error)
            }
        },

        /**
         * 根据设备id查询当前条目基本信息
         * @returns {Promise<void>}
         */
        async onDeviceInfoIdService() {
            try {
                let params = {
                    id:this.deviceInfo.id,
                    partsId:this.structuralId
                }
                const { code,data } = await getDeviceInfoIdService(params)
                if(code === 'SUCCESS'){
                    this.SET_DEVICE_INFO(data)
                }else{
                    Message.error('请求异常，请联系管理员！')
                }
            }catch (error){
                console.log(error)
            }
        },
        /**
         * 根据结构部件id查询当前条目基本信息
         * @returns {Promise<void>}
         */
        async onStructuralInfoIdService() {
            try {
                let params = {
                    id:this.structuralId,
                }
                const { code,data } = await getStructuralInfoIdService(params)
                if(code === 'SUCCESS'){
                    this.structuralInfo = data
                    this.$forceUpdate()
                }else{
                    Message.error('请求异常，请联系管理员！')
                }
            }catch (error){
                console.log(error)
            }
        },
        /**
         * 根据设备id查询结构部件数据
         */
        async onStructuralDeviceId() {
            try {
                this.deviceStructuralArray = []
                this.deviceInfoLoading = true
                let params = {
                    id:this.deviceInfo.id,
                }
                const { code,data } = await getStructuralDeviceIdService(params)
                if(code === 'SUCCESS'){
                    for(let i in data){
                        data[i].nameStatus = false
                        data[i].visible = false

                        if(data[i].childNode.length!==0){

                            for(let j in data[i].childNode){
                                data[i].childNode[j].visible = false
                            }
                        }
                    }
                    this.deviceStructuralArray = data
                }else{
                    Message.error('请求异常，请联系管理员！')
                }
                this.deviceInfoLoading = false
            }catch (error){
                this.deviceInfoLoading = false
                console.log(error)
            }
        },
    },
    /**
     * 销毁事件
     */
    destroyed() {
        $event.$off([EVENT_NAME.REASON_MODAL_VISIBLE])
    }
}
export default DeviceInfo
